<template>
	<div class="container-fluid">
		<div class="row vh-100">
			<div class="login-column col-md-6 bg-secondary h-100 d-flex justify-content-center align-items-center">
				<h1>Hello, API.</h1>
			</div>
			<div class="login-column col-md-6 bg-white h-100 d-flex justify-content-center align-items-center">
				<div class="ps-1 pe-1" style="width: 100%; max-width: 400px">
					<h2>Please log in</h2>
					<Form @submit="handleLogin" :validation-schema="schema">
						<div class="form-floating mb-3">
							<Field name="username" type="text" class="form-control" />
							<label for="username">Username</label>
							<ErrorMessage name="username" class="error-feedback d-block alert alert-warning" />
						</div>
						<div class="form-floating mb-3">
							<Field name="password" type="password" class="form-control" />
							<label for="password">Password</label>
							<ErrorMessage name="password" class="error-feedback d-block alert alert-warning" />
						</div>

						<button class="btn btn-primary btn-lg w-100" :disabled="loading">
							<span v-show="loading" class="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true"></span>
							<span>Login</span>
						</button>

						<div class="form-group">
							<div v-if="message" class="alert alert-danger" role="alert">
								{{ message }}
							</div>
						</div>
					</Form>
					<p class="mt-3">Not registered? <router-link to="/register"> Do it now!</router-link></p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {Form, Field, ErrorMessage} from 'vee-validate';
import * as yup from 'yup';

export default {
	name: 'AppLogin',
	components: {
		Form,
		Field,
		ErrorMessage,
	},
	data() {
		const schema = yup.object().shape({
			username: yup.string().required('Username is required!'),
			password: yup.string().required('Password is required!'),
		});

		return {
			loading: false,
			message: '',
			schema,
		};
	},
	methods: {
		handleLogin(user) {
			this.loading = true;

			this.$store.dispatch('auth/login', user).then(
				() => {
					this.$store.dispatch('auth/fetchRoles');
					this.$router.push('/home');
				},
				(error) => {
					this.loading = false;
					this.message = (error.response && error.response.data && error.response.data.error.message) || error.message || error.toString();
				}
			);
		},
	},
	computed: {
		loggedIn() {
			return this.$store.state.auth.status.loggedIn;
		},
	},
	created() {
		// If logged in redirect to home page
		if (this.loggedIn) {
			this.$router.push('/home');
		}
	},
};
</script>

<style scoped></style>
