import {createApp} from 'vue';
import App from './App.vue';

import router from './router';
import store from './store';

import Toaster from '@meforma/vue-toaster';
import moment from 'moment';

import 'bootstrap';
// Import BS 5.0 sass directly in App.vue
// import 'bootstrap/dist/css/bootstrap.min.css';

const app = createApp(App);

app.config.globalProperties.$filters = {
	formatDate(value) {
		if (value) {
			const dateParsed = moment(String(value));
			if (dateParsed.isValid()) {
				return dateParsed.format('YYYY-MM-DD');
			}
		}
		return moment().format('YYYY-MM-DD');
	},
	todayDate(value) {
		return moment().format('YYYY-MM-DD');
	},
};

app.use(router).use(store).use(Toaster, {position: 'top'}).mount('#app');
