<template>
	<div class="container-fluid">
		<div class="row vh-100">
			<div class="login-column col-md-6 bg-secondary h-100 d-flex justify-content-center align-items-center">
				<h1>Join, API.</h1>
			</div>
			<div class="login-column col-md-6 bg-white h-100 d-flex justify-content-center align-items-center">
				<div class="ps-1 pe-1" style="width: 100%; max-width: 400px;">
					<h2>Please register</h2>
					<div v-if="!successful">
						<Form @submit="handleRegister" :validation-schema="schema">
							<div class="form-floating mb-3">
								<Field name="username" type="text" class="form-control" />
								<label for="username">Username</label>
								<ErrorMessage name="username" class="error-feedback d-block alert alert-warning" />
							</div>
							<div class="form-floating mb-3">
								<Field name="email" type="email" class="form-control" />
								<label for="email">Email</label>
								<ErrorMessage name="email" class="error-feedback d-block alert alert-warning" />
							</div>
							<div class="form-floating mb-3">
								<Field name="password" type="password" class="form-control" />
								<label for="password">Password</label>
								<ErrorMessage name="password" class="error-feedback d-block alert alert-warning" />
							</div>

							<p v-if="message" class="error-feedback d-block alert alert-warning mb-3">{{ message }}</p>

							<button class="btn btn-primary btn-lg w-100" :disabled="loading">
								<span v-show="loading" class="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true"></span>
								<span>Register</span>
							</button>
						</Form>

						<p class="mt-3">Already registered? <router-link to="/login"> Log in now!</router-link></p>
					</div>

					<div v-if="successful" class="alert alert-success">
						{{ message }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {Form, Field, ErrorMessage} from 'vee-validate';
import * as yup from 'yup';

export default {
	name: 'AppRegister',
	components: {
		Form,
		Field,
		ErrorMessage,
	},
	data() {
		const schema = yup.object().shape({
			username: yup.string().required('Username is required!').min(3, 'Must be at least 3 characters!').max(20, 'Must be maximum 20 characters!'),
			email: yup.string().required('Email is required!').email('Email is invalid!').max(50, 'Must be maximum 50 characters!'),
			password: yup.string().required('Password is required!').min(6, 'Must be at least 6 characters!').max(40, 'Must be maximum 40 characters!'),
		});

		return {
			successful: false,
			loading: false,
			message: '',
			schema,
		};
	},
	methods: {
		handleRegister(user) {
			this.message = '';
			this.successful = false;
			this.loading = true;

			this.$store.dispatch('auth/register', user).then(
				(data) => {
					if (!data.error) {
						this.message = data.success.message;
						this.successful = true;
						this.loading = false;
					} else {
						this.message = data.error.message;
						this.successful = false;
						this.loading = false;
					}
				},
				(error) => {
					this.message = (error.response && error.response.data && error.response.data.error.message) || error.message || error.toString();
					this.successful = false;
					this.loading = false;
				}
			);
		},
	},
	computed: {
		loggedIn() {
			return this.$store.state.auth.status.loggedIn;
		},
	},
	mounted() {
		// If logged in redirect to profile page
		if (this.loggedIn) {
			this.$router.push('/profile');
		}
	},
};
</script>
