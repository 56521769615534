import axios from 'axios';

const API_URL = process.env.VUE_APP_AUTH_URL;

class AuthService {
	login(user) {
		return axios
			.post(API_URL + 'login', {
				username: user.username,
				password: user.password,
			})
			.then((response) => {
				if (response.data.accessToken) {
					localStorage.setItem('user', JSON.stringify(response.data));
				}
				return response.data;
			});
	}

	logout() {
		return axios.get(API_URL + 'logout').then(() => {
			localStorage.removeItem('user');
		});
	}

	register(user) {
		return axios.post(API_URL + 'register', {
			username: user.username,
			email: user.email,
			password: user.password,
		});
	}
}

export default new AuthService();
