import {createWebHistory, createRouter} from 'vue-router';

import Login from './components/Login.vue';
import Register from './components/Register.vue';
import Imprint from './components/Imprint.vue';

// lazy-loaded
const Home = () => import('./components/Home.vue');
const Profile = () => import('./components/Profile.vue');
const Files = () => import('./components/Files.vue');
const Items = () => import('./components/Items.vue');
const ItemEdit = () => import('./components/ItemEdit.vue');
const ItemInsert = () => import('./components/ItemInsert.vue');
const Users = () => import('./components/Users.vue');
const UserEdit = () => import('./components/UserEdit.vue');

const routes = [
	{
		path: '/',
		component: Login,
	},
	{
		path: '/login',
		component: Login,
	},
	{
		path: '/register',
		component: Register,
	},
	{
		path: '/imprint',
		component: Imprint,
	},
	{
		path: '/home',
		name: 'home',
		// lazy-loaded
		component: Home,
	},
	{
		path: '/profile',
		name: 'profile',
		// lazy-loaded
		component: Profile,
	},
	{
		path: '/users',
		name: 'users',
		// lazy-loaded
		component: Users,
	},
	{
		path: '/user-edit/:id',
		name: 'user-edit',
		// lazy-loaded
		component: UserEdit,
	},
	{
		path: '/files',
		name: 'files',
		// lazy-loaded
		component: Files,
	},
	{
		path: '/items',
		name: 'items',
		// lazy-loaded
		component: Items,
	},
	{
		path: '/item-edit/:id',
		name: 'item-edit',
		// lazy-loaded
		component: ItemEdit,
	},
	{
		path: '/item-insert',
		name: 'item-insert',
		// lazy-loaded
		component: ItemInsert,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	const publicPages = ['/login', '/register', '/imprint'];
	const authRequired = !publicPages.includes(to.path);
	const loggedIn = localStorage.getItem('user');

	// trying to access a restricted page + not logged in
	// redirect to login page
	if (authRequired && !loggedIn) {
		next('/login');
	} else {
		next();
	}
});

export default router;
